<template>
  <div>
      <v-alert
          v-if="error"
          dense
          type="error"
      >
        {{ error }}
      </v-alert>
    <v-row justify="center" class="my-4 mx-1">
      <v-col
        cols="12"
        md="6"
      >
        <v-card :loading="loadingNumberEvents">
          <v-card-text>
            <v-card-text>
              <v-row align="center">
                <v-col
                    cols="9"
                >
                  <div class="text-h4 text--primary">{{ counts.events }}</div>
                  <div>Évènements à venir</div>
                </v-col>
                <v-col
                    cols="3"
                >
                  <v-icon x-large>mdi-calendar</v-icon>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn block small @click="$router.push({ name: 'Agenda' })">
                Voir les évènements
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-card :loading="loadingNumberArtists">
          <v-card-text>
            <v-card-text>
              <v-row align="left">
                <v-col
                    cols="9"
                >
                  <div class="text-h4 text--primary">{{ counts.artistes }}</div>
                  <div>Artistes enregistré</div>
                </v-col>
                <v-col
                    cols="3"
                >
                  <v-icon x-large>mdi-account-music</v-icon>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn block small @click="$router.push({ name: 'Artistes' })">
                Voir tout les artistes
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="left" class="my-4 mx-1">
      <v-col
          cols="12"
          md="6"
      >
        <v-card>
          <v-card-title>
            Prochain évènement
          </v-card-title>
          <v-card-subtitle>
            <div class="text-h4">{{ nextEvent.title }}</div>
          </v-card-subtitle>
          <v-card-text>
            <div class="d-flex flex-wrap justify-sm-space-between align-center text--primary mb-3">
              <span><v-icon class="mr-1">mdi-calendar</v-icon> {{ nextEvent.dateStart}}</span>
              <v-icon class="mx-1">mdi-arrow-right-thin-circle-outline</v-icon>
              <span>{{ nextEvent.dateEnd }}</span>
            </div>

            <div class="d-flex flex-wrap justify-sm-space-between align-center text--primary mb-3">
              <span class="d-inline-block"><v-icon class="mr-1">mdi-account-music</v-icon> {{ nextEvent.artistes }}</span>
              <span class="d-inline-block"><v-icon class="mr-1">mdi-music</v-icon> {{ nextEvent.style }}</span>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn small block class="primary">Plus de détails</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import api from '../services/API'

export default {
  name: 'Dashboard',
  data() {
    return {
      nextEvent: {
        dateStart: new Date().toISOString(),
        dateEnd: new Date().toISOString(),
        title: 'Mon evenement',
        artistes: 'Noria Lilt & Eli Sab',
        style: 'Goa, Trance, Tribe'
      },
      loadingNumberArtists: false,
      loadingNumberEvents: false,
      error: null,
    }
  },
  computed: {
    ...mapState(['counts'])
  },
  methods: {
    ...mapActions(['updateDashboardCount'])
  },
  async mounted() {
    const counts = {
      artistes: null,
      events: null
    }

    this.loadingNumberArtists = true
    this.loadingNumberEvents = true

    await api.artiste.getArtistesCount()
        .then((responseArtiste) => {
          if (responseArtiste.data !== undefined) {
            counts.artistes = responseArtiste.data
          }
          this.loadingNumberArtists = false
        })
        .catch(() => {
          this.error = 'Une erreur c\'est produite lors du chargement des données, merci de réessayer plus tard. Si l\'erreur persiste contacter votre webmaster.'
          this.loadingNumberArtists = false
        })
    await api.events.getEventsCount()
        .then((reponseEvent) => {
          if (reponseEvent.data !== undefined) {
            counts.events = reponseEvent.data
          }
          this.loadingNumberEvents = false
          this.updateDashboardCount(counts)
        })
        .catch(() => {
          this.error = 'Une erreur c\'est produite lors du chargement des données, merci de réessayer plus tard. Si l\'erreur persiste contacter votre webmaster.'
          this.loadingNumberEvents = false
        })
  }
}
</script>
